import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MicroQACount from '../views/MicroQACount.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/allqa/:merchantcode?/:sku?/:token?/:domain?',
  name: 'home',
  component: Home,
  props: true
},
{
  path: '/qamicro/:merchantcode/:sku/:token?/:domain?',
  name: 'qamicro',
  component: MicroQACount,
  props: true
},
{
  path: '/askquestion',
  name: 'askquestion',
  props: true,
  component: () =>
      import(/* webpackChunkName: "askquestion" */ '../views/AskQuestion.vue')
},
{
  path: '/allanswers/:qid',
  name: 'allanswers',
  props: true,
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () =>
      import(/* webpackChunkName: "allanswers" */ '../views/AllAnswers.vue')
},
{
  path: '*',
  component: NotFound
}

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
