<template>
  <div class="af_useful_info af_flex af_align_items_center af_t_margin_sml">
    <small v-if="totalCount>0" :style="[$root.textColor,$root.sizeSmall]"><b><span itemprop="upvoteCount">{{helpful}}</span> of {{totalCount}}</b> found this helpful. Do you?</small>
    <small v-else :style="[$root.textColor,$root.sizeSmall]">Do you find this helpful?</small>
    <a href="javascript:void(0)" rel="nofollow" :style="[$root.primaryColorButton,$root.sizeLink]" :disabled="wasHelpful" @click="submitHelp('helpful')">Yes</a><a href="javascript:void(0)" rel="nofollow" :style="[$root.primaryColorButton,$root.sizeLink]" :disabled="wasNotHelpful" @click="submitHelp('nothelpful')">No</a>
  </div>
</template>

<script>
export default {
  name: 'Helpful',
  props: {
    propHelpfulCount: Number,
    propNothelpfulCount: Number,
    propanswerID: String
  },
  data () {
    return {
      wasHelpful: null,
      wasNotHelpful: null,
      helpful: this.propHelpfulCount,
      nothelpful: this.propNothelpfulCount,
      debounce: null,
      timer: 1000
    }
  },
  methods: {
    submitHelp: function (action) {
      clearTimeout(this.debounce) // If clicked, Remove the scheduled function.
      if (action === 'helpful') { // If helpful clicked
        if (this.propHelpfulCount < this.helpful) { // If same user already marked as helpful, then skipping another action
          return
        }

        if (this.propNothelpfulCount < this.nothelpful) { // If same user switching between helpful & not helpful
          this.nothelpful-- // Reducing the nothelpful count to keep only 1 vote per user per answer
          this.wasNotHelpful = false
        }
        this.helpful++
        this.wasHelpful = true
      } else {
        if (this.propNothelpfulCount < this.nothelpful) { // If same user already marked as nothelpful, then skipping another action
          return
        }

        if (this.propHelpfulCount < this.helpful) { // If same user switching between helpful & not helpful
          this.helpful-- // Reducing the helpful count to keep only 1 vote per user per answer
          this.wasHelpful = false
        }
        this.nothelpful++
        this.wasNotHelpful = true
      }

      this.debounce = setTimeout(() => { //  setting the timer to avoid multiple calls to server
        this.postHelp()
      }, this.timer)
    },
    postHelp: function () {
      let url = this.$siteURL + 'submithelpful?api_token=' + this.$root.token
      if (this.$root.productID) {
        url = this.$siteURL + 'submithelpful?shop=' + this.$root.domain
      }
      this.$axios
        .post(url, {
          helpfulAction: this.helpfulAction,
          decHelpful: this.decHelpful,
          decNotHelpful: this.decNotHelpful,
          answerID: this.propanswerID

        })
        .then(response => {

        })
        .catch(error => {
          this.$Console.log(error)
        })
    }
  },
  computed: {
    totalCount: function () {
      return this.helpful + this.nothelpful
    },
    helpfulAction: function () {
      if (this.wasHelpful) {
        return 'helpful'
      } else {
        return 'not_helpful'
      }
    },
    decHelpful: function () {
      if (this.wasHelpful === false) {
        return 'true'
      } else {
        return 'false'
      }
    },
    decNotHelpful: function () {
      if (this.wasNotHelpful === false) {
        return 'true'
      } else {
        return 'false'
      }
    }
  }
}
</script>
