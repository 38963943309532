<template>
  <div class="af_like_btn af_flex af_flex_column af_align_items_center">
    <div class="af_thumbup af_icon" :disabled="hasVotedUp" @click="submitVote('add')">
        <span class="icon-arrowUp" :style="upSelected"></span>
      </div>
        <div class="af_vote_number af_align_text_center" itemprop="upvoteCount" v-if="votes>=0" :style="[$root.textColor,$root.sizeSmall]">{{votes}}<br />
          <span class="af_hide_xs" :style="[$root.textColor,$root.sizeSmall]">votes</span>
        </div>
        <div class="af_vote_number af_align_text_center" itemprop="downvoteCount" v-else :style="[$root.textColor,$root.sizeSmall]">{{votes}}<br />
          <span class="af_hide_xs" :style="[$root.textColor,$root.sizeSmall]">votes</span>
        </div>
        <div class="af_thumbdown af_icon" :disabled="hasVotedDown" @click="submitVote('subtract')">
          <span class="icon-arrowDown" :style="downSelected"></span>
        </div>
      <!-- <a class="af_thumbup" :disabled="hasVotedUp" href="javascript:void(0)" @click="submitVote('add')">
        <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" :fill="upSelected" viewBox="0 0 18 18">
          <path d="M18 13.5l-9-9-9 9z"/>
        </svg>
        </a>
        <div class="af_vote_number af_align_text_center" :style="[$root.textColor,$root.sizeSmall]">{{votes}}<br />
          <span class="af_hide_xs" :style="[$root.textColor,$root.sizeSmall]">votes</span></div>
        <a ref="downVote" class="af_thumbdown" :disabled="hasVotedDown" href="javascript:void(0)" @click="submitVote('subtract')">
        <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" :fill="downSelected" viewBox="0 0 18 18">
          <path d="M0 4.5l9 9 9-9z"/>
        </svg>

        </a> -->
        </div>
</template>

<script>
export default {
  name: 'Votes',
  data () {
    return {
      votes: this.totalVotes,
      hasVotedUp: false,
      hasVotedDown: false,
      defaultColor: this.$root.textColor,
      upSelected: this.$root.textColor,
      downSelected: this.$root.textColor
    }
  },
  props: {
    totalVotes: Number,
    questionID: String
  },
  methods: {
    submitVote: function (action) {
      if (action === 'add') {
        if (this.totalVotes < this.votes) {
          return
        }
        this.votes++
        this.upSelected = this.$root.primaryColor
        this.downSelected = this.defaultColor
        this.hasVotedUp = true
      } else {
        if (this.totalVotes > this.votes) {
          return
        }
        this.votes--
        this.downSelected = this.$root.primaryColor
        this.upSelected = this.defaultColor
        this.hasVotedDown = true
      }

      let url = this.$siteURL + 'submitvote?api_token=' + this.$root.token
      if (this.$root.productID) {
        url = this.$siteURL + 'submitvote?shop=' + this.$root.domain
      }
      this.$axios
        .post(url, {
          questionID: this.questionID,
          voteAction: action

        })
        .then(response => {

        })
        .catch(error => {
          this.$Console.log(error)
        })
    }
  }
}
</script>
